export default [
  {
    path: '/apps/delivery_status/list/',
    name: 'apps-delivery-status-list',
    redirect: to => {
      return { path: '/apps/entity/list/delivery_status/delivery_status' }
    },
  },
  {
    path: '/apps/delivery_status/view/:id',
    name: 'apps-delivery-status-view',
    redirect: to => {
      return { path: '/apps/entity/view/delivery_status/'+to.params.id }
    },
  },
  {
    path: '/apps/delivery_status/edit/:id',
    name: 'apps-delivery-status-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/delivery_status/delivery_status/'+to.params.id }
    },
  },
  {
    path: '/apps/delivery_status/create/',
    name: 'apps-delivery-status-create',
    redirect: to => {
      return { path: '/apps/entity/create/delivery_status/delivery_status' }
    },
  }
]
