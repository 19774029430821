export default [
  {
    path: '/apps/employment/list/',
    name: 'apps-employment-list',
    redirect: to => {
      return { path: '/apps/entity/list/employment/employment' }
    },
  },
  {
    path: '/apps/employment/view/:id',
    name: 'apps-employment-view',
    redirect: to => {
      return { path: '/apps/entity/view/employment/'+to.params.id }
    },
  },
  {
    path: '/apps/employment/edit/:id',
    name: 'apps-employment-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/employment/employment/'+to.params.id }
    },
  },
  {
    path: '/apps/employment/create/',
    name: 'apps-employment-create',
    redirect: to => {
      return { path: '/apps/entity/create/employment/employment' }
    },
  }
]
