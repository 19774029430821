export default [
  {
    path: '/admin/services/bundles/list',
    name: 'apps-service-bundles-list',
    component: () => import('@/views/service/bundles/list/'),
    meta: {
      pageTitle: 'Lista de Paquetes de Servicios',
      breadcrumb: [
        {
          text: 'Paquetes de Servicios',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  },
  {
    path: '/admin/services/bundles/view/:id',
    name: 'apps-service-bundles-view',
    component: () => import('@/views/service/bundles/view/'),
  },
  {
    path: '/admin/services/bundles/edit/:id',
    name: 'apps-service-bundles-edit',
    component: () => import('@/views/service/bundles/edit/'),
  },
  {
    path: '/apps/services/bundles/create/',
    name: 'apps-service-bundles-create',
    component: () => import('@/views/service/bundles/edit/'),
  }
]
