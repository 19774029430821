export default [
  {
    path: '/apps/boolean/list/',
    name: 'apps-boolean-list',
    redirect: to => {
      return { path: '/apps/entity/list/boolean/boolean' }
    },
  },
  {
    path: '/apps/boolean/view/:id',
    name: 'apps-boolean-view',
    redirect: to => {
      return { path: '/apps/entity/view/boolean/'+to.params.id }
    },
  },
  {
    path: '/apps/boolean/edit/:id',
    name: 'apps-boolean-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/boolean/boolean/'+to.params.id }
    },
  },
  {
    path: '/apps/boolean/create/',
    name: 'apps-boolean-create',
    redirect: to => {
      return { path: '/apps/entity/create/boolean/boolean' }
    },
  }
]
