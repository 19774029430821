export default [
  {
    path: '/meet/local/:case_uuid',
    name: 'apps-meet-local',
    component: () => import('@/views/meet/local'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/meet/local_follow/:case_uuid',
    name: 'apps-meet-local-follow',
    component: () => import('@/views/meet/local_follow'),
    meta: {
      layout: 'full',
    },
  },
]
