export default [
    {
      path: '/dates/schedules/calendar',
      name: 'apps-schedules',
      component: () => import('@/views/schedules/calendar-doctor/'),
      meta: {
        pageTitle: 'Agendas',
        breadcrumb: [
          {
            text: 'Agendas',
            active: true,
          },
        ]
      }
    }
  ]
  