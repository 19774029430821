export default [
  {
    path: '/auth_final/list',
    name: 'auth-final-list',
    component: () => import('@/views/service_auth_final/list/'),
    meta: {
      pageTitle: 'Lista de Autorizaciones definitivas',
      breadcrumb: [
        {
          text: 'Autorizaciones definitivas',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  },
  {
    path: '/auth_final/edit/:id',
    name: 'auth-final-edit',
    component: () => import('@/views/service_auth_final/edit/'),
  }
]
