import i18n from '@/libs/i18n'
export default [
  {
    path: '/apps/company/list/:type/:resource_name',
    name: 'apps-company-list',
    component: () => import('@/views/company/list/'),
    props: true,
    meta: {
      pageTitle: 'Lista de :resource_name',
      breadcrumb: [
        {
          text: ':resource_name',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    },
    beforeEnter: (to, from, next) => {
      const resource_name = to.params.resource_name
      if (resource_name) {
        const resource_text = i18n.t(resource_name + '.name')
        to.meta.pageTitle = 'Lista de '+ resource_text
        to.meta.breadcrumb[0].text = resource_text
      }
      next()
    }
  },
  {
    path: '/apps/company/edit/:resource_name/:id',
    name: 'apps-company-edit',
    component: () => import('@/views/company/edit/'),
  },
  {
    path: '/apps/company/create/:type/:resource_name',
    name: 'apps-company-create',
    component: () => import('@/views/company/edit/'),
  },
  {
    path: '/apps/company/view/:resource_name/:id',
    name: 'apps-company-view',
    component: () => import('@/views/company/view/'),
  },
]
