import axios from '@axios'

export default {
    namespaced: true,
    state: {
        searchData: null,
        currentPage: 1,
    },
    getters: {},
    mutations: {
        setSearchData(state, data) {
            state.searchData = data
        },
        setCurrentPage(state, page) {
            state.currentPage = page
        },
    },
    actions: {
        fetch_elements(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/v1/cases/search`, {params: params.queryParams})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
