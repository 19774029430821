export default [
  {
    path: '/apps/sex/list/',
    name: 'apps-sex-list',
    redirect: to => {
      return { path: '/apps/entity/list/sex/sex' }
    },
  },
  {
    path: '/apps/sex/view/:id',
    name: 'apps-sex-view',
    redirect: to => {
      return { path: '/apps/entity/view/sex/'+to.params.id }
    },
  },
  {
    path: '/apps/sex/edit/:id',
    name: 'apps-sex-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/sex/sex/'+to.params.id }
    },
  },
  {
    path: '/apps/sex/create/',
    name: 'apps-sex-create',
    redirect: to => {
      return { path: '/apps/entity/create/sex/sex' }
    },
  }
]
