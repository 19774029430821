export default [
  {
    path: '/apps/logbook_reason/list/',
    name: 'apps-logbook-reason-list',
    redirect: to => {
      return { path: '/apps/entity/list/logbook_reason/logbook_reason' }
    },
  },
  {
    path: '/apps/logbook_reason/view/:id',
    name: 'apps-logbook-reason-view',
    redirect: to => {
      return { path: '/apps/entity/view/logbook_reason/'+to.params.id }
    },
  },
  {
    path: '/apps/logbook_reason/edit/:id',
    name: 'apps-logbook-reason-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/logbook_reason/logbook_reason/'+to.params.id }
    },
  },
  {
    path: '/apps/logbook_reason/create/',
    name: 'apps-logbook-reason-create',
    redirect: to => {
      return { path: '/apps/entity/create/logbook_reason/logbook_reason' }
    },
  }
]
