export default [
  {
    path: '/admin/services/list',
    name: 'apps-services-list',
    component: () => import('@/views/service/list/'),
    meta: {
      pageTitle: 'Lista de Servicios',
      breadcrumb: [
        {
          text: 'Servicios',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  },
  {
    path: '/admin/services/view/:id',
    name: 'apps-services-view',
    component: () => import('@/views/service/view/'),
  },
  {
    path: '/admin/services/edit/:id',
    name: 'apps-services-edit',
    component: () => import('@/views/service/edit/'),
  },
  {
    path: '/apps/services/create/',
    name: 'apps-services-create',
    component: () => import('@/views/service/edit/'),
  }
]
