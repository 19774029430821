export default [
  {
    path: '/apps/diagnosis/list/',
    name: 'apps-diagnosis-list',
    redirect: to => {
      return { path: '/apps/entity/list/diagnosis/diagnosis' }
    },
  },
  {
    path: '/apps/diagnosis/view/:id',
    name: 'apps-diagnosis-view',
    redirect: to => {
      return { path: '/apps/entity/view/diagnosis/'+to.params.id }
    },
  },
  {
    path: '/apps/diagnosis/edit/:id',
    name: 'apps-diagnosis-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/diagnosis/diagnosis/'+to.params.id }
    },
  },
  {
    path: '/apps/diagnosis/create/',
    name: 'apps-diagnosis-create',
    redirect: to => {
      return { path: '/apps/entity/create/diagnosis/diagnosis' }
    },
  }
]
