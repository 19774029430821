export default [
  {
    path: '/vaccine_schema/list',
    name: 'vaccine-schema-list',
    component: () => import('@/views/service_vaccine_schema/list/'),
    meta: {
      pageTitle: 'Lista de Esquemas de Vacunación',
      breadcrumb: [
        {
          text: 'Esquemas de Vacunación',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  },
  {
    path: '/vaccine_schema/edit/:id',
    name: 'vaccine-schema-edit',
    component: () => import('@/views/service_vaccine_schema/edit/'),
  }
]
