export default [
    {
      path: '/dates/availabilities/list',
      name: 'apps-availabilities-list',
      component: () => import('@/views/availability/list/'),
      meta: {
        pageTitle: 'Lista de Disponibilidades',
        breadcrumb: [
          {
            text: 'Disponibilidades',
          },
          {
            text: 'Lista',
            active: true,
          },
        ]
      }
    },
    {
      path: '/dates/availabilities/view/:id',
      name: 'apps-availabilities-view',
      component: () => import('@/views/availability/view/'),
    },
    {
      path: '/dates/availabilities/edit/:id',
      name: 'apps-availabilities-edit',
      component: () => import('@/views/availability/edit/'),
    },
    {
      path: '/apps/availabilities/create/',
      name: 'apps-availabilities-create',
      component: () => import('@/views/availability/edit/'),
    }
  ]
  