export default [
  {
    path: '/public/first_date_documents/:case_uuid/:prst_uuid',
    name: 'first-date-documents',
    component: () => import('@/views/public/first_date_documents/'),
    meta: {
      layout: 'full',
    },
  },
]
