export default [
  {
    path: '/apps/invoice_group/list/',
    name: 'apps-invoice-group-list',
    redirect: to => {
      return { path: '/apps/entity/list/invoice_group/invoice_group' }
    },
  },
  {
    path: '/apps/invoice_group/view/:id',
    name: 'apps-invoice-group-view',
    redirect: to => {
      return { path: '/apps/entity/view/invoice_group/'+to.params.id }
    },
  },
  {
    path: '/apps/invoice_group/edit/:id',
    name: 'apps-invoice-group-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/invoice_group/invoice_group/'+to.params.id }
    },
  },
  {
    path: '/apps/invoice_group/create/',
    name: 'apps-invoice-group-create',
    redirect: to => {
      return { path: '/apps/entity/create/invoice_group/invoice_group' }
    },
  }
]
