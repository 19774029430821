export default [
  {
    path: '/cases/case/support/list',
    name: 'apps-case-support-list',
    component: () => import('@/views/case/support/list/'),
    meta: {
      pageTitle: 'Lista de Casos',
      breadcrumb: [
        {
          text: 'Casos',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  },
  {
    path: '/cases/case/support/list_support_positives',
    name: 'apps-case-support-positives-list',
    component: () => import('@/views/case/support/list_positives/'),
    meta: {
      pageTitle: 'Lista de Casos',
      breadcrumb: [
        {
          text: 'Casos',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  },
  {
    path: '/cases/case/support/list_transcript_pending',
    name: 'apps-case-support-transcript-pending-list',
    component: () => import('@/views/case/support/list_transcript_pending/'),
    meta: {
      pageTitle: 'Lista de Casos',
      breadcrumb: [
        {
          text: 'Casos',
        },
        {
          text: 'Lista',
          active: true,
        },
        ]
      },
  },
  {
    path: '/cases/case/support/list_reschedule',
    name: 'apps-case-support-reschedule-list',
    component: () => import('@/views/case/support/list_reschedule/'),
    meta: {
      pageTitle: 'Lista de Casos',
      breadcrumb: [
        {
          text: 'Casos',
        },
        {
          text: 'Lista',
          active: true,
        },
        ]
      },
  },
  {
    path: '/cases/case/support/list_closed',
    name: 'apps-case-support-closed-list',
    component: () => import('@/views/case/support/list_closed/'),
    meta: {
      pageTitle: 'Lista de Casos',
      breadcrumb: [
        {
          text: 'Casos',
        },
        {
          text: 'Lista',
          active: true,
        },
        ]
      },
  },
  {
    path: '/cases/case/support/list_pending_exams',
    name: 'apps-case-support-pending-exams-list',
    component: () => import('@/views/case/support/list_pending_exams/'),
    meta: {
      pageTitle: 'Lista de Casos',
      breadcrumb: [
        {
          text: 'Casos',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  },
  {
    path: '/cases/case/support/list_initial_pending_exams',
    name: 'apps-case-support-initial-pending-exams-list',
    component: () => import('@/views/case/support/list_initial_pending_exams/'),
    meta: {
      pageTitle: 'Lista de Casos',
      breadcrumb: [
        {
          text: 'Casos',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  },
  {
    path: '/cases/case/support/list_uncomplete_exams',
    name: 'apps-case-support-uncomplete-exams-list',
    component: () => import('@/views/case/support/list_uncomplete_exams/'),
    meta: {
      pageTitle: 'Lista de Casos',
      breadcrumb: [
        {
          text: 'Casos',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  },
  {
    path: '/cases/case/support/list_pending_arls',
    name: 'apps-case-support-pending-arls-list',
    component: () => import('@/views/case/support/list_pending_arls/'),
    meta: {
      pageTitle: 'Lista de Casos',
      breadcrumb: [
        {
          text: 'Casos',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  },
  {
    path: '/cases/case/support/list_pending_auth',
    name: 'apps-case-support-pending-auth-list',
    component: () => import('@/views/case/support/list_pending_auth/'),
    meta: {
      pageTitle: 'Lista de Casos',
      breadcrumb: [
        {
          text: 'Casos',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  },
  {
    path: '/cases/case/support/list_complete_results',
    name: 'apps-case-support-complete-results-list',
    component: () => import('@/views/case/support/list_complete_results/'),
    meta: {
      pageTitle: 'Lista de Casos',
      breadcrumb: [
        {
          text: 'Casos',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    },
  },
  {
    path: '/cases/case/support/list_following_without_labs',
    name: 'apps-case-support-following-without-labs-list',
    component: () => import('@/views/case/support/list_following_without_labs/'),
    meta: {
      pageTitle: 'Lista de Casos',
      breadcrumb: [
        {
          text: 'Casos',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    },
  },
]
