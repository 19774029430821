export default [
  {
    path: '/apps/case_status/list/',
    name: 'apps-case-status-list',
    redirect: to => {
      return { path: '/apps/entity/list/case_status/case_status' }
    },
  },
  {
    path: '/apps/case_status/view/:id',
    name: 'apps-case-status-view',
    redirect: to => {
      return { path: '/apps/entity/view/case_status/'+to.params.id }
    },
  },
  {
    path: '/apps/case_status/edit/:id',
    name: 'apps-case-status-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/case_status/case_status/'+to.params.id }
    },
  },
  {
    path: '/apps/case_status/create/',
    name: 'apps-case-status-create',
    redirect: to => {
      return { path: '/apps/entity/create/case_status/case_status' }
    },
  }
]
