export default [
  {
    path: '/admin/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/user/list/'),
    meta: {
      roles: ['SUPER_ADMINISTRATOR'],
      pageTitle: 'Lista de usuarios',
      breadcrumb: [
        {
          text: 'Usuarios',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  },
  {
    path: '/admin/users/view/:id',
    name: 'apps-users-view',
    component: () => import('@/views/user/view/'),
    meta: {
      roles: ['SUPER_ADMINISTRATOR']
    }
  },
  {
    path: '/admin/users/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/user/edit/'),
    meta: {
      roles: ['SUPER_ADMINISTRATOR']
    }
  }
]
