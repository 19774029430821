export default [
  {
    path: '/admin/enterprise/list/:type',
    name: 'apps-enterprise-list',
    redirect: to => {
      return { path: '/apps/company/list/ENTERPRISE/enterprise' }
    },
    meta: {
      pageTitle: 'Lista de Empresas',
      breadcrumb: [
        {
          text: 'Empresas',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  },
  {
    path: '/admin/enterprise/view/:id',
    name: 'apps-enterprise-view',
    redirect: to => {
      return { path: '/apps/company/view/enterprise/'+to.params.id }
    },
  },
  {
    path: '/admin/enterprise/edit/:id',
    name: 'apps-enterprise-edit',
    redirect: to => {
      return { path: '/apps/company/edit/enterprise/'+to.params.id }
    },
  },
  {
    path: '/apps/enterprise/create/',
    name: 'apps-enterprise-create',
    redirect: to => {
      return { path: '/apps/company/create/ENTERPRISE/enterprise' }
    },
  }
]
