export default [
  {
    path: '/admin/config_notification/list',
    name: 'apps-config-notification-list',
    component: () => import('@/views/config_notification/list/'),
    meta: {
      pageTitle: 'Lista de Configuraciones de notificación',
      breadcrumb: [
        {
          text: 'Configuraciones de notificación',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  },
  {
    path: '/admin/config_notification/view/:id',
    name: 'apps-config-notification-view',
    component: () => import('@/views/config_notification/view/'),
  },
  {
    path: '/admin/config_notification/edit/:id',
    name: 'apps-config-notification-edit',
    component: () => import('@/views/config_notification/edit/'),
  },
  {
    path: '/apps/config_notification/create/',
    name: 'apps-config-notification-create',
    component: () => import('@/views/config_notification/edit/'),
  }
]
