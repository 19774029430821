export default [
    {
      path: '/apps/relationship/list/',
      name: 'apps-relationship-list',
      redirect: to => {
        return { path: '/apps/entity/list/relationship/relationship' }
      },
    },
    {
      path: '/apps/relationship/view/:id',
      name: 'apps-relationship-view',
      redirect: to => {
        return { path: '/apps/entity/view/relationship/'+to.params.id }
      },
    },
    {
      path: '/apps/relationship/edit/:id',
      name: 'apps-relationship-edit',
      redirect: to => {
        return { path: '/apps/entity/edit/relationship/relationship/'+to.params.id }
      },
    },
    {
      path: '/apps/relationship/create/',
      name: 'apps-relationship-create',
      redirect: to => {
        return { path: '/apps/entity/create/relationship/relationship' }
      },
    }
  ]