export default [
  {
    path: '/apps/job/list/',
    name: 'apps-job-list',
    redirect: to => {
      return { path: '/apps/entity/list/job/job' }
    },
  },
  {
    path: '/apps/job/view/:id',
    name: 'apps-job-view',
    redirect: to => {
      return { path: '/apps/entity/view/job/'+to.params.id }
    },
  },
  {
    path: '/apps/job/edit/:id',
    name: 'apps-job-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/job/job/'+to.params.id }
    },
  },
  {
    path: '/apps/job/create/',
    name: 'apps-job-create',
    redirect: to => {
      return { path: '/apps/entity/create/job/job' }
    },
  }
]
