import handlerDataSearch from '@/handlers/handlerDataSearch'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    searchData: null,
    currentPage: 1,
    abortController: null,
  },
  getters: {
  },
  mutations: {
    setUpdateState(state, data) {
      state.searchData = data
      state.currentPage = data.page
    },
    SET_CONTROLLER(state, controller) {
      state.abortController = controller
    },
    CLEAR_CONTROLLER(state) {
      state.abortController = null
    },
  },
  actions: {
    fetch_elements_following_without_labs({ commit, state }, params) {
      if (state.abortController) {
        state.abortController.abort()
        commit('CLEAR_CONTROLLER')
      }
      const controller = new AbortController()
      commit('SET_CONTROLLER', controller)

      const currentParams = handlerDataSearch(params)
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/case/case_following_without_labs', { params: currentParams, signal: controller.signal })
          .then(response => {
            commit('CLEAR_CONTROLLER')
            resolve(response)
          })
          .catch(error => {
            if (error.name === 'AbortError' || error.message === 'canceled') {
              console.log('Petición abortada por el usuario')
              resolve([])
            } else {
              console.log('Error en la petición', error.message)
              reject(error)
            }
            commit('CLEAR_CONTROLLER')
          })
      })
    },
    fetch_elements_complete_results({ commit, state }, params) {
      if (state.abortController) {
        state.abortController.abort()
        commit('CLEAR_CONTROLLER')
      }
      const controller = new AbortController()
      commit('SET_CONTROLLER', controller)

      const currentParams = handlerDataSearch(params)
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/case/case_complete_results', { params: currentParams, signal: controller.signal })
          .then(response => {
            commit('CLEAR_CONTROLLER')
            resolve(response)
          })
          .catch(error => {
            if (error.name === 'AbortError' || error.message === 'canceled') {
              console.log('Petición abortada por el usuario')
              resolve([])
            } else {
              console.log('Error en la petición', error.message)
              reject(error)
            }
            commit('CLEAR_CONTROLLER')
          })
      })
    },
    fetch_elements_transcript_pending({ commit, state }, params) {
      if (state.abortController) {
        state.abortController.abort()
        commit('CLEAR_CONTROLLER')
      }
      const controller = new AbortController()
      commit('SET_CONTROLLER', controller)

      const currentParams = handlerDataSearch(params)
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/case/case_transcript_pending', { params: currentParams, signal: controller.signal })
          .then(response => {
            commit('CLEAR_CONTROLLER')
            resolve(response)
          })
          .catch(error => {
            if (error.name === 'AbortError' || error.message === 'canceled') {
              console.log('Petición abortada por el usuario')
              resolve([])
            } else {
              console.log('Error en la petición', error.message)
              reject(error)
            }
            commit('CLEAR_CONTROLLER')
          })
      })
    },
    fetch_elements_closed({ commit, state }, params) {
      if (state.abortController) {
        state.abortController.abort()
        commit('CLEAR_CONTROLLER')
      }
      const controller = new AbortController()
      commit('SET_CONTROLLER', controller)

      const currentParams = handlerDataSearch(params)
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/case/case_closed', { params: currentParams, signal: controller.signal })
          .then(response => {
            commit('CLEAR_CONTROLLER')
            resolve(response)
          })
          .catch(error => {
            if (error.name === 'AbortError' || error.message === 'canceled') {
              console.log('Petición abortada por el usuario')
              resolve([])
            } else {
              console.log('Error en la petición', error.message)
              reject(error)
            }
            commit('CLEAR_CONTROLLER')
          })
      })
    },
    fetch_elements_reschedule({ commit, state }, params) {
      if (state.abortController) {
        state.abortController.abort()
        commit('CLEAR_CONTROLLER')
      }
      const controller = new AbortController()
      commit('SET_CONTROLLER', controller)

      const currentParams = handlerDataSearch(params)
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/case/case_reschedule', { params: currentParams, signal: controller.signal })
          .then(response => {
            commit('setUpdateState', response.data)
            commit('CLEAR_CONTROLLER')
            resolve(response)
          })
          .catch(error => {
            if (error.name === 'AbortError' || error.message === 'canceled') {
              console.log('Petición abortada por el usuario')
              resolve([])
            } else {
              console.log('Error en la petición', error.message)
              reject(error)
            }
            commit('CLEAR_CONTROLLER')
          })
      })
    },
    fetch_elements_pending_exams({ commit, state }, params) {
      if (state.abortController) {
        state.abortController.abort()
        commit('CLEAR_CONTROLLER')
      }
      const controller = new AbortController()
      commit('SET_CONTROLLER', controller)

      const currentParams = handlerDataSearch(params)
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/case/fetch_elements_pending_exams', { params: currentParams, signal: controller.signal })
          .then(response => {
            commit('CLEAR_CONTROLLER')
            resolve(response)
          })
          .catch(error => {
            if (error.name === 'AbortError' || error.message === 'canceled') {
              console.log('Petición abortada por el usuario')
              resolve([])
            } else {
              console.log('Error en la petición', error.message)
              reject(error)
            }
            commit('CLEAR_CONTROLLER')
          })
      })
    },
    fetch_elements_uncomplete_exams(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/case/fetch_elements_uncomplete_exams', { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_elements_initial_pending_exams({ commit, state }, params) {
      if (state.abortController) {
        state.abortController.abort()
        commit('CLEAR_CONTROLLER')
      }
      const controller = new AbortController()
      commit('SET_CONTROLLER', controller)

      const currentParams = handlerDataSearch(params)
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/case/fetch_elements_initial_pending_exams', { params: currentParams, signal: controller.signal })
          .then(response => {
            commit('CLEAR_CONTROLLER')
            resolve(response)
          })
          .catch(error => {
            if (error.name === 'AbortError' || error.message === 'canceled') {
              console.log('Petición abortada por el usuario')
              resolve([])
            } else {
              console.log('Error en la petición', error.message)
              reject(error)
            }
            commit('CLEAR_CONTROLLER')
          })
      })
    },
    fetch_elements_pending_arls(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/case/fetch_elements_pending_arls', { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_elements_pending_auth(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/case/fetch_elements_pending_auth', { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_elements_positives({ commit, state }, params) {
      if (state.abortController) {
        state.abortController.abort()
        commit('CLEAR_CONTROLLER')
      }
      const controller = new AbortController()
      commit('SET_CONTROLLER', controller)

      const currentParams = handlerDataSearch(params)
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/case/case_positives', { params: currentParams, signal: controller.signal })
          .then(response => {
            commit('CLEAR_CONTROLLER')
            resolve(response)
          })
          .catch(error => {
            if (error.name === 'AbortError' || error.message === 'canceled') {
              console.log('Petición abortada por el usuario')
              resolve([])
            } else {
              console.log('Error en la petición', error.message)
              reject(error)
            }
            commit('CLEAR_CONTROLLER')
          })
      })
    },
    fetch_elements_support(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/case/case_first_date_scheduled', { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_other_elements(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/case/${params.patient_id}/${params.case_id}`, { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_element(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/case/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_case_date(ctx, { step_id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/process/case_date/step/${step_id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delete_element(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/cases/case/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    add_element(ctx, elementData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/cases/case', elementData.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    save_element(ctx, elementData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/cases/case/${elementData.data.id}`, elementData.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_config_notification(ctx, { step_id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/process/config_notification/step/${step_id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    send_notification(ctx, { noti_id, case_id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/process/process/send_notification/${noti_id}/${case_id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_key_value(ctx, { type }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/core/type_value/${type}`, {
            params: { deep: 1 },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDepartments(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/geography', {
            params: { deep: 2 },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCities(ctx, { parent }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/geography', {
            params: { deep: 3, parent },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_arls(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/company', {
            params: {
              perPage: 500, page: 1, companyType: 'ARL', only_active: true,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_company(ctx, { nit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/enterprise', {
            params: { nit },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_patient(ctx, { document_type_id, identity }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/patient_by_identity', {
            params: { document_type_id, identity },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fecth_users(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/doctor')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchImage(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/core/image/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
