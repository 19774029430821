export default [
  {
    path: '/apps/population_zone/list/',
    name: 'apps-population-zone-list',
    redirect: to => {
      return { path: '/apps/entity/list/population_zone/population_zone' }
    },
  },
  {
    path: '/apps/population_zone/view/:id',
    name: 'apps-population-zone-view',
    redirect: to => {
      return { path: '/apps/entity/view/population_zone/'+to.params.id }
    },
  },
  {
    path: '/apps/population_zone/edit/:id',
    name: 'apps-population-zone-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/population_zone/population_zone/'+to.params.id }
    },
  },
  {
    path: '/apps/population_zone/create/',
    name: 'apps-population-zone-create',
    redirect: to => {
      return { path: '/apps/entity/create/population_zone/population_zone' }
    },
  }
]
