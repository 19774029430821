export default [
  {
    path: '/apps/bundle_name/list/',
    name: 'apps-bundle-name-list',
    redirect: to => {
      return { path: '/apps/entity/list/bundle_name/bundle_name' }
    },
  },
  {
    path: '/apps/bundle_name/view/:id',
    name: 'apps-bundle-name-view',
    redirect: to => {
      return { path: '/apps/entity/view/bundle_name/'+to.params.id }
    },
  },
  {
    path: '/apps/bundle_name/edit/:id',
    name: 'apps-bundle-name-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/bundle_name/bundle_name/'+to.params.id }
    },
  },
  {
    path: '/apps/bundle_name/create/',
    name: 'apps-bundle-name-create',
    redirect: to => {
      return { path: '/apps/entity/create/bundle_name/bundle_name' }
    },
  }
]
