export default [
  {
    path: '/admin/supplier/list/:type',
    name: 'apps-supplier-list',
    redirect: to => {
      return { path: '/apps/company/list/SUPPLIER/supplier' }
    },
    meta: {
      pageTitle: 'Lista de Proveedores',
      breadcrumb: [
        {
          text: 'Proveedores',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  },
  {
    path: '/admin/supplier/view/:id',
    name: 'apps-supplier-view',
    redirect: to => {
      return { path: '/apps/company/view/supplier/'+to.params.id }
    },
  },
  {
    path: '/admin/supplier/edit/:id',
    name: 'apps-supplier-edit',
    redirect: to => {
      return { path: '/apps/company/edit/supplier/'+to.params.id }
    },
  },
  {
    path: '/apps/supplier/create/',
    name: 'apps-supplier-create',
    redirect: to => {
      return { path: '/apps/company/create/SUPPLIER/supplier' }
    },
  }
]
