export default [
  {
    path: '/admin/arls/list/:type',
    name: 'apps-arls-list',
    redirect: to => {
      return { path: '/apps/company/list/ARL/arls' }
    },
    meta: {
      pageTitle: 'Lista de ARLs',
      breadcrumb: [
        {
          text: 'Arls',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  },
  {
    path: '/admin/arls/view/:id',
    name: 'apps-arls-view',
    redirect: to => {
      return { path: '/apps/company/view/arls/'+to.params.id }
    },
  },
  {
    path: '/admin/arls/edit/:id',
    name: 'apps-arls-edit',
    redirect: to => {
      return { path: '/apps/company/edit/arls/'+to.params.id }
    },
  },
  {
    path: '/apps/arls/create/',
    name: 'apps-arls-create',
    redirect: to => {
      return { path: '/apps/company/create/ARL/arls' }
    },
  }
]
