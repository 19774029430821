export default [
  {
    path: '/apps/invoice-status/list/',
    name: 'apps-invoice-status-list',
    redirect: to => {
      return { path: '/apps/entity/list/invoice_status/invoice_status' }
    },
  },
  {
    path: '/apps/invoice_status/view/:id',
    name: 'apps-invoice_status-view',
    redirect: to => {
      return { path: '/apps/entity/view/invoice_status/'+to.params.id }
    },
  },
  {
    path: '/apps/invoice_status/edit/:id',
    name: 'apps-invoice-status-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/invoice_status/invoice_status/'+to.params.id }
    },
  },
  {
    path: '/apps/invoice_status/create/',
    name: 'apps-invoice-status-create',
    redirect: to => {
      return { path: '/apps/entity/create/invoice_status/invoice_status' }
    },
  }
]
