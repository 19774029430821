export default [
  {
    path: '/apps/process_step/list/',
    name: 'apps-process-step-list',
    redirect: to => {
      return { path: '/apps/entity/list/process_step/process_step' }
    },
  },
  {
    path: '/apps/process_step/view/:id',
    name: 'apps-process-step-view',
    redirect: to => {
      return { path: '/apps/entity/view/process_step/'+to.params.id }
    },
  },
  {
    path: '/apps/process_step/edit/:id',
    name: 'apps-process-step-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/process_step/process_step/'+to.params.id }
    },
  },
  {
    path: '/apps/process_step/create/',
    name: 'apps-process-step-create',
    redirect: to => {
      return { path: '/apps/entity/create/process_step/process_step' }
    },
  }
]
