export default [
  {
    path: '/consult/edition_time/',
    name: 'apps-edition-time-edit',
    component: () => import('@/views/edition_time/edit/'),
    meta: {
      pageTitle: 'Tiempo de edición de consulta',
      breadcrumb: [
        {
          text: 'Tiempo de edición de consulta',
          active: true,
        },
      ]
    }
  },
]
