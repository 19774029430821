export default [
  {
    path: '/apps/injury_agent/list/',
    name: 'apps-injury-agent-list',
    redirect: to => {
      return { path: '/apps/entity/list/injury_agent/injury_agent' }
    },
  },
  {
    path: '/apps/injury_agent/view/:id',
    name: 'apps-injury-agent-view',
    redirect: to => {
      return { path: '/apps/entity/view/injury_agent/'+to.params.id }
    },
  },
  {
    path: '/apps/injury_agent/edit/:id',
    name: 'apps-injury-agent-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/injury_agent/injury_agent/'+to.params.id }
    },
  },
  {
    path: '/apps/injury_agent/create/',
    name: 'apps-injury-agent-create',
    redirect: to => {
      return { path: '/apps/entity/create/injury_agent/injury_agent' }
    },
  }
]
