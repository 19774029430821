const addDefaultMessageToChildren = (obj, defaultMessage = null) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }
  if (Array.isArray(obj)) {
    if (defaultMessage && typeof defaultMessage === 'object') {
      return [...defaultMessage, ...obj]
    }
    return obj

  }

  const newObj = { ...obj }
  Object.keys(newObj).forEach(key => {
    if (Object.prototype.hasOwnProperty.call(newObj, key) && typeof newObj[key] === 'object') {
      newObj[key] = addDefaultMessageToChildren(newObj[key], defaultMessage)
    }
  })
  return newObj
}

const searchObjectData = (obj, keys, defaultMessage = null) => {
  const result = {}

  const search = (curr_obj) => {
    if (typeof curr_obj !== 'object' || curr_obj === null) {
      return
    }

    if (Array.isArray(curr_obj)) {
      return curr_obj
    } else {
      Object.keys(curr_obj).forEach(key => {
        if (Object.prototype.hasOwnProperty.call(curr_obj, key)) {
          if (!keys || keys.includes(key)) {
            result[key] = addDefaultMessageToChildren(curr_obj[key], defaultMessage)
          }
          search(curr_obj[key])
        }
      })
    }
  }

  search(obj)

  return result
}

export default searchObjectData