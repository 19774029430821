import handlerDefaultSelect from '@/handlers/handlerDefaultSelect'
import searchObjectData from '@/handlers/searchObjectData'
import axios from '@axios'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {},
  getters: {
    getState(state) {
      return state
    },
    getDataList: (state) => ({ listState = null, messageDefault = null }) => {
      let list = null
      let in_default = null
      list = listState.length ? listState : null
      list = list && !Array.isArray(list) ? [list] : list
      if (list.length) {
        in_default = handlerDefaultSelect(messageDefault)
      }
      const dataList = state || JSON.parse(localStorage.getItem('dataList'))
      if (Array.isArray(list)) {
        const data = searchObjectData(dataList, list, in_default)
        if (data && Object.keys(data).length > 1) return data
        return Object.values(data)[0]
      }
    },
    getStateList: (state, { listState }) => {
      let list = listState || null
      const data_list = {}
      if (!list) {
        return null
      }
      if (list && !Array.isArray(list)) {
        list = [list]
      }
      if (Array.isArray(list)) {
        Object.keys(list).forEach(key => {
          if (Object.prototype.hasOwnProperty.call(list, key)) {
            const search = searchObjectData(state, key)
            if (search) {
              data_list[key] = search
            }
          }
        })
        return data_list
      }
      return null
    },
    getArlCodesList: state => {
      const in_default = handlerDefaultSelect('SELECT_ALL')
      return {
        arl: { ...in_default, ...state?.arl } || null,
        serviceType: { ...in_default, ...state?.serviceType } || null,
        authStatus: { ...in_default, ...state?.medicalOrderStatus?.authStatus } || null,
      }
    },
  },
  mutations: {
    setState(state, payload) {
      Object.assign(state, payload)
    },
    updateState(state, payload) {
      Object.keys(payload).forEach(key => {
        Vue.set(state, key, payload[key]);
      });
    },
  },
  actions: {
    initialize_lists({ commit }) {
      const dataList = JSON.parse(localStorage.getItem('dataList'))
      if (!Object.keys(dataList).length) {
        commit('setState', dataList)
      }
    },
    fetch_lists({ commit }, { _selector = null, _default = null, _country = null }) {
      // const { dataList } = JSON.parse(localStorage.getItem('dataList'))
      // if (Object.keys(dataList).length) {
      //   commit('setState', dataList)
      //   return dataList
      // }

      const params = new URLSearchParams({
        selector: _selector,
        default: _default,
        country: _country,
      }).toString()

      return new Promise((resolve, reject) => {
        axios.get(`/api/v1/cases/selector_search?${params}`)
          .then(response => {
            commit('setState', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    fetch_add_list({ state, commit }, { _selector = null, _default = null }) {
      let select = _selector
      if (!select) {
        return state
      }
      if (typeof select === 'string') {
        select = [select]
      }

      if (Object.keys(state).length && Array.isArray(select)) {
        const dataList_Keys = Object.keys(state)
        select = select.filter(key => !dataList_Keys.includes(key))
      }

      if (!select.length) {
        return state
      }

      const params = new URLSearchParams({
        selector: select.join(','),
        default: _default,
        country: null,
      }).toString()

      return new Promise((resolve, reject) => {
        axios.get(`/api/v1/cases/selector_search?${params}`)
          .then(response => {
            commit('updateState', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
  },
}
