export default [
  {
    path: '/billing/billing',
    name: 'apps-billing-billing',
    component: () => import('@/views/billing/search/index.vue'),
    meta: {
      pageTitle: 'Servicios pendientes por facturar',
      breadcrumb: [
        {
          text: 'Servicios pendientes por facturar',
          active: true,
        },
      ]
    }
  },
  {
    path: '/billing/payment-term-list',
    name: 'apps-billing-payment-term',
    component: () => import('@/views/billing/paymentterm/list/'),
  },
  {
    path: '/billing/payment-term/:id',
    name: 'apps-payment-term-edit',
    component: () => import('@/views/billing/paymentterm/edit/'),
  },
  {
    path: '/billing/payment-term/',
    name: 'apps-payment-term-create',
    component: () => import('@/views/billing/paymentterm/edit/'),
  },
  {
    path: '/billing/consecutive-invoice-list',
    name: 'apps-billing-consecutive-invoice',
    component: () => import('@/views/billing/consecutive_invoice/list/'),
  },
  {
    path: '/billing/consecutive-invoice/:id',
    name: 'apps-billing-consecutive-invoice-edit',
    component: () => import('@/views/billing/consecutive_invoice/edit/'),
  },
  {
    path: '/billing/consecutive-invoice/',
    name: 'apps-billing-consecutive-invoice-create',
    component: () => import('@/views/billing/consecutive_invoice/edit/'),
  },
  {
    path: '/billing/service-account-list',
    name: 'apps-billing-service-account',
    component: () => import('@/views/billing/service_account/list/'),
  },
  {
    path: '/billing/service-account-term/:id',
    name: 'apps-billing-service-account-edit',
    component: () => import('@/views/billing/service_account/edit/'),
  },
  {
    path: '/billing/service-account/',
    name: 'apps-billing-service-account-create',
    component: () => import('@/views/billing/service_account/edit/'),
  },
  {
    path: '/billing/invoice-list/:arl_id/:date',
    name: 'apps-billing-invoice',
    component: () => import('@/views/billing/invoice/list/'),
  },
  {
    path: '/billing/invoice-search/',
    name: 'apps-billing-invoice-search',
    component: () => import('@/views/billing/invoice/search/'),
  },
  {
    path: '/billing/invoice-consult/',
    name: 'apps-billing-invoice-consult',
    component: () => import('@/views/billing/invoice/consult/'),
  },
  {
    path: '/billing/invoice-consult/:id',
    name: 'apps-billing-invoice-consult-edit',
    component: () => import('@/views/billing/invoice/edit/'),
  },
  {
    path: '/billing/invoice-detail/:id',
    name: 'apps-billing-invoice-detail',
    component: () => import('@/views/patient/medical_history/invoice_detail'),
  },
  {
    path: '/billing/view/:id',
    name: 'apps-billing-view',
    component: () => import('@/views/billing/invoice/view/'),
  },
  {
    path: '/billing/payment-upload/',
    name: 'apps-billing-payment-upload',
    component: () => import('@/views/billing/payment/upload/'),
    meta: {
      pageTitle: 'Importar pagos recibidos',
      breadcrumb: [
        {
          text: 'Facturación',
          active: true,
        },
      ]
    }
  },
]
