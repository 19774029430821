export default [
  {
    path: '/admin/holidays/list',
    name: 'apps-holidays-list',
    component: () => import('@/views/holidays/edit/'),
    meta: {
      pageTitle: 'Lista de Festivos',
      breadcrumb: [
        {
          text: 'Holidays',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  }
]
