export default [
  {
    path: '/admin/patients/list',
    name: 'apps-patients-list',
    component: () => import('@/views/patient/list/'),
    meta: {
      pageTitle: 'Lista de Trabajadores',
      breadcrumb: [
        {
          text: 'Patients',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  },
  {
    path: '/admin/patients/view/:id',
    name: 'apps-patients-view',
    component: () => import('@/views/patient/view/'),
  },
  {
    path: '/admin/patients/edit/:id',
    name: 'apps-patients-edit',
    component: () => import('@/views/patient/edit/'),
  },
  {
    path: '/admin/patients/digital_file/:id',
    name: 'apps-patients-digital-file',
    component: () => import('@/views/patient/digital_file/'),
  },
  {
    path: '/apps/patients/create/',
    name: 'apps-patients-create',
    component: () => import('@/views/patient/edit/'),
  }
]
