export default [
  {
    path: '/apps/process_noti_medium/list/',
    name: 'apps-process-noti-medium-list',
    redirect: to => {
      return { path: '/apps/entity/list/process_noti_medium/process_noti_medium' }
    },
  },
  {
    path: '/apps/process_noti_medium/view/:id',
    name: 'apps-process-noti-medium-view',
    redirect: to => {
      return { path: '/apps/entity/view/process_noti_medium/'+to.params.id }
    },
  },
  {
    path: '/apps/process_noti_medium/edit/:id',
    name: 'apps-process-noti-medium-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/process_noti_medium/process_noti_medium/'+to.params.id }
    },
  },
  {
    path: '/apps/process_noti_medium/create/',
    name: 'apps-process-noti-medium-create',
    redirect: to => {
      return { path: '/apps/entity/create/process_noti_medium/process_noti_medium' }
    },
  }
]
