export default [
    {
        path: '/logbook_invoice/list/:id',
        name: 'apps-logbook-invoice-list',
        component: () => import('@/views/billing/logbook_invoice/list/'),
        meta: {
            pageTitle: 'Registros de Bitácora - Factura # ### ',
            breadcrumb: [
                {
                    text: 'Bitácora de Facturas',
                },
                {
                    text: 'Lista',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            const invoice_id = to.params.id;
            if (invoice_id) {
                to.meta.pageTitle = `Registros de Bitácora`;
            }
            next();
        },
    },
    {
        path: '/logbook_invoice/create/:invoice_id',
        name: 'apps-logbook-invoice-create',
        component: () => import('@/views/billing/logbook_invoice/edit/'),
    },
    {
        path: '/logbook_invoice/view/:id',
        name: 'apps-logbook-invoice-view',
        component: () => import('@/views/billing/logbook_invoice/view/'),
    },
];
