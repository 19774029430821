export default [
  {
    path: '/admin/cases/upload',
    name: 'apps-cases-upload',
    component: () => import('@/views/case/upload/'),
    meta: {
      pageTitle: 'Importación de casos',
      breadcrumb: [
        {
          text: 'Casos',
        },
        {
          text: 'Importar',
          active: true,
        },
      ]
    }
  }
]
