import { getRoles } from '@/auth/utils'
import ability from './ability'

export const canNavigate = to => {
  if (!to.meta.roles) {
    return true
  }

  const roles = getRoles()
  let hasPermission = false
  for (let j = 0; j < to.meta.roles.length; j++) {
    if (roles.has(to.meta.roles[j])) {
      hasPermission = true
      break
    }
  }
  return hasPermission
}

export const _ = undefined
