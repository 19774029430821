export default [
  {
    path: '/apps/injury_time/list/',
    name: 'apps-injury-time-list',
    redirect: to => {
      return { path: '/apps/entity/list/injury_time/injury_time' }
    },
  },
  {
    path: '/apps/injury_time/view/:id',
    name: 'apps-injury-time-view',
    redirect: to => {
      return { path: '/apps/entity/view/injury_time/'+to.params.id }
    },
  },
  {
    path: '/apps/injury_time/edit/:id',
    name: 'apps-injury-time-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/injury_time/injury_time/'+to.params.id }
    },
  },
  {
    path: '/apps/injury_time/create/',
    name: 'apps-injury-time-create',
    redirect: to => {
      return { path: '/apps/entity/create/injury_time/injury_time' }
    },
  }
]
