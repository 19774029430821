export default [
  {
    path: '/apps/medicine_group/list/',
    name: 'apps-medicine-group-list',
    redirect: to => {
      return { path: '/apps/entity/list/medicine_group/medicine_group' }
    },
  },
  {
    path: '/apps/medicine_group/view/:id',
    name: 'apps-medicine-group-view',
    redirect: to => {
      return { path: '/apps/entity/view/medicine_group/'+to.params.id }
    },
  },
  {
    path: '/apps/medicine_group/edit/:id',
    name: 'apps-medicine-group-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/medicine_group/medicine_group/'+to.params.id }
    },
  },
  {
    path: '/apps/medicine_group/create/',
    name: 'apps-medicine-group-create',
    redirect: to => {
      return { path: '/apps/entity/create/medicine_group/medicine_group' }
    },
  }
]
