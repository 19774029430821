export default [
  {
    path: '/apps/consult_type/list/',
    name: 'apps-consult-type-list',
    redirect: to => {
      return { path: '/apps/entity/list/consult_type/consult_type' }
    },
  },
  {
    path: '/apps/consult_type/view/:id',
    name: 'apps-consult-type-view',
    redirect: to => {
      return { path: '/apps/entity/view/consult_type/'+to.params.id }
    },
  },
  {
    path: '/apps/consult_type/edit/:id',
    name: 'apps-consult-type-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/consult_type/consult_type/'+to.params.id }
    },
  },
  {
    path: '/apps/consult_type/create/',
    name: 'apps-consult-type-create',
    redirect: to => {
      return { path: '/apps/entity/create/consult_type/consult_type' }
    },
  }
]
