export default [
  {
    path: '/apps/folder_type/list/',
    name: 'apps-folder-type-list',
    redirect: to => {
      return { path: '/apps/entity/list/folder_type/folder_type' }
    },
  },
  {
    path: '/apps/folder_type/view/:id',
    name: 'apps-folder-type-view',
    redirect: to => {
      return { path: '/apps/entity/view/folder_type/'+to.params.id }
    },
  },
  {
    path: '/apps/folder_type/edit/:id',
    name: 'apps-folder-type-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/folder_type/folder_type/'+to.params.id }
    },
  },
  {
    path: '/apps/folder_type/create/',
    name: 'apps-folder-type-create',
    redirect: to => {
      return { path: '/apps/entity/create/folder_type/folder_type' }
    },
  }
]
