export default [
  {
    path: '/freezer_scheduled/list',
    name: 'freezer-scheduled-list',
    component: () => import('@/views/freezer/scheduled/list/'),
    meta: {
      pageTitle: 'Envío de neveras',
      breadcrumb: [
        {
          text: 'Envío de neveras',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  },
  {
    path: '/freezer_scheduled/edit/:id',
    name: 'freezer-scheduled-edit',
    component: () => import('@/views/freezer/scheduled/edit/'),
  },
  {
    path: '/freezer_scheduled/view/:id',
    name: 'freezer-scheduled-view',
    component: () => import('@/views/freezer/scheduled/view/'),
  },
  {
    path: '/freezer_scheduled/view_order/:id',
    name: 'freezer-scheduled-order-view',
    component: () => import('@/views/freezer/scheduled/view_order/'),
  }
]
