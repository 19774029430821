export default [
  {
    path: '/apps/genre/list/',
    name: 'apps-genre-list',
    redirect: to => {
      return { path: '/apps/entity/list/genre/genre' }
    },
  },
  {
    path: '/apps/genre/view/:id',
    name: 'apps-genre-view',
    redirect: to => {
      return { path: '/apps/entity/view/genre/'+to.params.id }
    },
  },
  {
    path: '/apps/genre/edit/:id',
    name: 'apps-genre-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/genre/genre/'+to.params.id }
    },
  },
  {
    path: '/apps/genre/create/',
    name: 'apps-genre-create',
    redirect: to => {
      return { path: '/apps/entity/create/genre/genre' }
    },
  }
]
