export default [
  {
    path: '/auth_pending/list',
    name: 'auth-pending-list',
    component: () => import('@/views/service_auth_pending/list/'),
    meta: {
      pageTitle: 'Lista de Autorizaciones pendientes',
      breadcrumb: [
        {
          text: 'Autorizaciones pendientes',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  },
  {
    path: '/auth_pending/edit/:id',
    name: 'auth-pending-edit',
    component: () => import('@/views/service_auth_pending/edit/'),
  }
]
