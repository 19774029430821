export default [
  {
    path: '/freezer_pending/list',
    name: 'freezer-pending-list',
    component: () => import('@/views/freezer/pending/list/'),
    meta: {
      pageTitle: 'Lista de Neveras pendientes',
      breadcrumb: [
        {
          text: 'Neveras pendientes',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  },
  {
    path: '/freezer_pending/edit/:id',
    name: 'freezer-pending-edit',
    component: () => import('@/views/freezer/pending/edit/'),
  },
  {
    path: '/freezer_pending/view/:id',
    name: 'freezer-pending-view',
    component: () => import('@/views/freezer/pending/view/'),
  }
]
