export default [
    {
      path: '/dates/my_schedule/list',
      name: 'apps-my-schedule-list',
      component: () => import('@/views/case/received/list_medical_schedule/'),
      meta: {
        pageTitle: 'Lista de Casos',
        breadcrumb: [
          {
            text: 'Casos',
          },
          {
            text: 'Lista',
            active: true,
          },
        ]
      }
    }
]