export default [
  {
    path: '/apps/exam_result/list/',
    name: 'apps-exam-result-list',
    redirect: to => {
      return { path: '/apps/entity/list/exam_result/exam_result' }
    },
  },
  {
    path: '/apps/exam_result/view/:id',
    name: 'apps-exam-result-view',
    redirect: to => {
      return { path: '/apps/entity/view/exam_result/'+to.params.id }
    },
  },
  {
    path: '/apps/exam_result/edit/:id',
    name: 'apps-exam-result-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/exam_result/exam_result/'+to.params.id }
    },
  },
  {
    path: '/apps/exam_result/create/',
    name: 'apps-exam-result-create',
    redirect: to => {
      return { path: '/apps/entity/create/exam_result/exam_result' }
    },
  }
]
