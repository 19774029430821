export default [
  {
    path: '/apps/dosage_form/list/',
    name: 'apps-dosage-form-list',
    redirect: to => {
      return { path: '/apps/entity/list/dosage_form/dosage_form' }
    },
  },
  {
    path: '/apps/dosage_form/view/:id',
    name: 'apps-dosage-form-view',
    redirect: to => {
      return { path: '/apps/entity/view/dosage_form/'+to.params.id }
    },
  },
  {
    path: '/apps/dosage_form/edit/:id',
    name: 'apps-dosage-form-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/dosage_form/dosage_form/'+to.params.id }
    },
  },
  {
    path: '/apps/dosage_form/create/',
    name: 'apps-dosage-form-create',
    redirect: to => {
      return { path: '/apps/entity/create/dosage_form/dosage_form' }
    },
  }
]
