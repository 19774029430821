import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

// Importa tus módulos Vuex
import app from './app'
import appConfig from './app-config'
import caseSearch from '../views/case/search/storeModule'
import dataList from './data-list' // Importa el módulo dataList
import listSearch from '../views/case/support/storeModule'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

// Configura VuexPersist
const vuexPersist = new VuexPersist({
  key: 'dataList', // clave para almacenar en localStorage
  storage: window.localStorage, // o sessionStorage o localStorage
  modules: ['dataList'], // solo persistir el módulo dataList
})

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    caseSearch,
    dataList,
    listSearch,
    verticalMenu,
  },
  strict: process.env.DEV,
  plugins: [vuexPersist.plugin],
})
