export default [
  {
    path: '/apps/freezer_next_actions/list/',
    name: 'apps-freezer-next-actions-list',
    redirect: to => {
      return { path: '/apps/entity/list/freezer_next_actions/freezer_next_actions' }
    },
  },
  {
    path: '/apps/freezer_next_actions/view/:id',
    name: 'apps-freezer-next-actions-view',
    redirect: to => {
      return { path: '/apps/entity/view/freezer_next_actions/'+to.params.id }
    },
  },
  {
    path: '/apps/freezer_next_actions/edit/:id',
    name: 'apps-freezer-next-actions-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/freezer_next_actions/freezer_next_actions/'+to.params.id }
    },
  },
  {
    path: '/apps/freezer_next_actions/create/',
    name: 'apps-freezer-next-actions-create',
    redirect: to => {
      return { path: '/apps/entity/create/freezer_next_actions/freezer_next_actions' }
    },
  }
]
