import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import reception_status from '@/router/routes/type_value/freezer/reception_status'
import service_bundles from '@/router/routes/services/bundles'
import bundle_name from '@/router/routes/type_value/services/bundle_name'
import auth from './routes/auth'
import user from './routes/user'
import arl from './routes/companies/arl'
import enterprise from './routes/companies/enterprise'
import supplier from './routes/companies/supplier'
import company from './routes/companies/company'
import patient from './routes/patient'
import job from './routes/type_value/job'
import sex from './routes/type_value/sex'
import employment from './routes/type_value/employment'
import medical_history from './routes/process/medical_history'
import logbook from './routes/logbook/logbook'
import logbook_invoice from './routes/logbook/logbook_invoice'
import logbook_medium from './routes/type_value/logbook/logbook_medium'
import logbook_result from './routes/type_value/logbook/logbook_result'
import logbook_reason from './routes/type_value/logbook/logbook_reason'
import eps from './routes/type_value/eps'
import genre from './routes/type_value/genre'
import population_zone from './routes/type_value/population_zone'
import sample_status from './routes/type_value/freezer/sample_status'
import freezer_status from './routes/type_value/freezer/freezer_status'
import delivery_status from './routes/type_value/freezer/delivery_status'
import freezer_next_actions from './routes/type_value/freezer/freezer_next_actions'
import document_type from './routes/type_value/document_type'
import case_type from './routes/type_value/case_type'
import case_status from './routes/type_value/case_status'
import case_tracking_type from './routes/type_value/case_tracking_type'
import service_type from './routes/type_value/service_type'
import consult_type from './routes/type_value/consult_type'
import company_type from './routes/type_value/company/company_type'
import supplier_type from './routes/type_value/company/supplier_type'
import contact_type from './routes/type_value/contact_type'
import occupation from './routes/type_value/occupation'
import exam_result from './routes/type_value/process/exam_result'
import diagnosis from './routes/type_value/process/diagnosis'
import process_noti_type from './routes/type_value/process/process_noti_type'
import process_noti_medium from './routes/type_value/process/process_noti_medium'
import process_step from './routes/type_value/process/process_step'
import config_notification from './routes/process/config_notification'
import arl_codes from './routes/services_following/arl_codes'
import freezer from './routes/freezer/freezer'
import freezer_pending from './routes/freezer/freezer_pending'
import freezer_scheduled from './routes/freezer/freezer_scheduled'
import auth_pending from './routes/services_following/auth_pending'
import vaccine_schema from './routes/services_following/vaccine_schema'
import auth_final from './routes/services_following/auth_final'
import medicine_delivery from './routes/services_following/medicine_delivery'
import entity from './routes/type_value/entity'
import country from './routes/type_value/countrys'
import department from './routes/type_value/department'
import city from './routes/type_value/city'
import boolean from './routes/type_value/boolean'
import booleanNA from './routes/type_value/booleanNA'
import administration_route from './routes/type_value/medicine/administration_route'
import delivery_unit from './routes/type_value/medicine/delivery_unit'
import medicine_group from './routes/type_value/medicine/medicine_group'
import dosage_form from './routes/type_value/medicine/dosage_form'
import reference_unit from './routes/type_value/medicine/reference_unit'
import units_of_measure from './routes/type_value/medicine/units_of_measure'
import service from './routes/service'
import service_cost from './routes/services/service_cost'
import stock_medicine from './routes/stock_medicine'
import case_ from './routes/cases/import_case'
import consent from './routes/public/consent'
import first_date_documents from './routes/public/first_date_documents'
import date_type from './routes/type_value/process/date_type'
import case_received from './routes/cases/received'
import availability from './routes/dates/availability'
import schedules from './routes/dates/schedules'
import my_medical_schedule from './routes/dates/my_medical_schedule'
import support from './routes/support/support_cases'
import meet from './routes/dates/meet'
import meet_extern from './routes/public/meet_extern'
import documental_type from './routes/digital_file/documental_type'
import folder_type from './routes/type_value/digital_file/folder_type'
import holiday_type from './routes/type_value/holiday_types'
import holidays from './routes/holidays'
import template from './routes/template'
import search from './routes/cases/search'
import injury_agent from './routes/type_value/consult/injury_agent'
import injury_time from './routes/type_value/consult/injury_time'
import compromised_liquid from './routes/type_value/consult/compromised_liquid'
import ecp_purpose from './routes/type_value/consult/ecp_purpose'
import labtests from './routes/cases/labtests'
import relationship from './routes/type_value/relationship'
import billing from './routes/billing'
import billing_payment_term from './routes/type_value/billing_payment_term'
import invoice_status from './routes/type_value/invoice_status'
import invoice_group from './routes/type_value/invoice_group'
import edition_time from './routes/edition_time'
import process_messages from './routes/type_value/process/process_messages'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
    },
    ...auth,
    ...user,
    ...boolean,
    ...booleanNA,
    ...administration_route,
    ...delivery_unit,
    ...dosage_form,
    ...reference_unit,
    ...units_of_measure,
    ...medicine_group,
    ...arl,
    ...enterprise,
    ...supplier,
    ...eps,
    ...process_noti_type,
    ...process_noti_medium,
    ...process_step,
    ...diagnosis,
    ...config_notification,
    ...arl_codes,
    ...freezer_pending,
    ...freezer,
    ...freezer_scheduled,
    ...auth_pending,
    ...auth_final,
    ...medicine_delivery,
    ...medical_history,
    ...logbook,
    ...logbook_invoice,
    ...logbook_reason,
    ...logbook_result,
    ...logbook_medium,
    ...job,
    ...entity,
    ...occupation,
    ...exam_result,
    ...company,
    ...document_type,
    ...case_type,
    ...case_status,
    ...case_tracking_type,
    ...service_type,
    ...consult_type,
    ...company_type,
    ...supplier_type,
    ...patient,
    ...contact_type,
    ...country,
    ...department,
    ...city,
    ...sex,
    ...genre,
    ...service,
    ...stock_medicine,
    ...service_bundles,
    ...bundle_name,
    ...employment,
    ...case_,
    ...case_received,
    ...availability,
    ...schedules,
    ...my_medical_schedule,
    ...support,
    ...meet,
    ...meet_extern,
    ...documental_type,
    ...folder_type,
    ...consent,
    ...first_date_documents,
    ...template,
    ...date_type,
    ...search,
    ...holidays,
    ...holiday_type,
    ...injury_agent,
    ...injury_time,
    ...compromised_liquid,
    ...ecp_purpose,
    ...labtests,
    ...relationship,
    ...billing,
    ...billing_payment_term,
    ...invoice_status,
    ...service_cost,
    ...vaccine_schema,
    ...population_zone,
    ...sample_status,
    ...freezer_status,
    ...delivery_status,
    ...reception_status,
    ...freezer_next_actions,
    ...invoice_group,
    ...edition_time,
    ...process_messages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (to.name === 'auth-login') {
    if (isLoggedIn) {
      const userData = getUserData()
      return next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }
    return next()
  }

  if (to.path === '/password') {
    return next()
  }

  if (!to.path.startsWith('/public/')) {
    if (isLoggedIn) {
      if (!canNavigate(to)) {
        return next({ name: 'not-authorized' })
      }
    } else {
      return next({ name: 'auth-login' })
    }
  }

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
