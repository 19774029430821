export default [
  {
    path: '/apps/units_of_measure/list/',
    name: 'apps-units-of-measure-list',
    redirect: to => {
      return { path: '/apps/entity/list/units_of_measure/units_of_measure' }
    },
  },
  {
    path: '/apps/units_of_measure/view/:id',
    name: 'apps-units-of-measure-view',
    redirect: to => {
      return { path: '/apps/entity/view/units_of_measure/'+to.params.id }
    },
  },
  {
    path: '/apps/units_of_measure/edit/:id',
    name: 'apps-units-of-measure-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/units_of_measure/units_of_measure/'+to.params.id }
    },
  },
  {
    path: '/apps/units_of_measure/create/',
    name: 'apps-units-of-measure-create',
    redirect: to => {
      return { path: '/apps/entity/create/units_of_measure/units_of_measure' }
    },
  }
]
