export default [
  {
    path: '/apps/administration_route/list/',
    name: 'apps-administration-route-list',
    redirect: to => {
      return { path: '/apps/entity/list/administration_route/administration_route' }
    },
  },
  {
    path: '/apps/administration_route/view/:id',
    name: 'apps-administration-route-view',
    redirect: to => {
      return { path: '/apps/entity/view/administration_route/'+to.params.id }
    },
  },
  {
    path: '/apps/administration_route/edit/:id',
    name: 'apps-administration-route-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/administration_route/administration_route/'+to.params.id }
    },
  },
  {
    path: '/apps/administration_route/create/',
    name: 'apps-administration-route-create',
    redirect: to => {
      return { path: '/apps/entity/create/administration_route/administration_route' }
    },
  }
]
