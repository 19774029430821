export default [
  {
    path: '/apps/billing_payment_term/list/',
    name: 'apps-billing-payment-term-list',
    redirect: to => {
      return { path: '/apps/entity/list/billing_payment_term/billing_payment_term' }
    },
  },
  {
    path: '/apps/billing_payment_term/view/:id',
    name: 'apps-billing-payment-term-view',
    redirect: to => {
      return { path: '/apps/entity/view/billing_payment_term/'+to.params.id }
    },
  },
  {
    path: '/apps/billing_payment_term/edit/:id',
    name: 'apps-billing-payment-term-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/billing_payment_term/billing_payment_term/'+to.params.id }
    },
  },
  {
    path: '/apps/billing_payment_term/create/',
    name: 'apps-billing-payment-term-create',
    redirect: to => {
      return { path: '/apps/entity/create/billing_payment_term/billing_payment_term' }
    },
  }
]
