import SessionStorage from './SessionStorage'

const handlerDataSearch = params => {
  const currentParams = { ...params.queryParams }
  const search = new SessionStorage('avenir', null, params.queryParams)
  const isEqual = search.isEqualToStorage(null, ['page'])
  if (!isEqual) {
    currentParams.page = 1
    search.updateObjectStorage(currentParams)
  } else {
    search.updateObjectStorage()
  }
  return currentParams
}

export default handlerDataSearch
