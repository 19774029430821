export default [
  {
    path: '/apps/logbook_medium/list/',
    name: 'apps-logbook-medium-list',
    redirect: to => {
      return { path: '/apps/entity/list/logbook_medium/logbook_medium' }
    },
  },
  {
    path: '/apps/logbook_medium/view/:id',
    name: 'apps-logbook-medium-view',
    redirect: to => {
      return { path: '/apps/entity/view/logbook_medium/'+to.params.id }
    },
  },
  {
    path: '/apps/logbook_medium/edit/:id',
    name: 'apps-logbook-medium-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/logbook_medium/logbook_medium/'+to.params.id }
    },
  },
  {
    path: '/apps/logbook_medium/create/',
    name: 'apps-logbook-medium-create',
    redirect: to => {
      return { path: '/apps/entity/create/logbook_medium/logbook_medium' }
    },
  }
]
