export default [
  {
    path: '/apps/case_tracking_type/list/',
    name: 'apps-case-tracking-type-list',
    redirect: to => {
      return { path: '/apps/entity/list/case_tracking_type/case_tracking_type' }
    },
  },
  {
    path: '/apps/case_tracking_type/view/:id',
    name: 'apps-case-tracking-type-view',
    redirect: to => {
      return { path: '/apps/entity/view/case_tracking_type/'+to.params.id }
    },
  },
  {
    path: '/apps/case_tracking_type/edit/:id',
    name: 'apps-case-tracking-type-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/case_tracking_type/case_tracking_type/'+to.params.id }
    },
  },
  {
    path: '/apps/case_tracking_type/create/',
    name: 'apps-case-tracking-type-create',
    redirect: to => {
      return { path: '/apps/entity/create/case_tracking_type/case_tracking_type' }
    },
  }
]
