export default [
  {
    path: '/apps/ecp_purpose/list/',
    name: 'apps-ecp-purpose-list',
    redirect: to => {
      return { path: '/apps/entity/list/ecp_purpose/ecp_purpose' }
    },
  },
  {
    path: '/apps/ecp_purpose/view/:id',
    name: 'apps-ecp-purpose-view',
    redirect: to => {
      return { path: '/apps/entity/view/ecp_purpose/'+to.params.id }
    },
  },
  {
    path: '/apps/ecp_purpose/edit/:id',
    name: 'apps-ecp-purpose-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/ecp_purpose/ecp_purpose/'+to.params.id }
    },
  },
  {
    path: '/apps/ecp_purpose/create/',
    name: 'apps-ecp-purpose-create',
    redirect: to => {
      return { path: '/apps/entity/create/ecp_purpose/ecp_purpose' }
    },
  }
]
