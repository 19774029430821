export default [
  {
    path: '/medicine_delivery/list',
    name: 'medicine-delivery-list',
    component: () => import('@/views/medicine_delivery/list/'),
    meta: {
      pageTitle: 'Lista de Medicamentos por entregar',
      breadcrumb: [
        {
          text: 'Entrega de medicamentos',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  },
  {
    path: '/medicine_delivery/edit/:id',
    name: 'medicine-delivery-edit',
    component: () => import('@/views/medicine_delivery/edit/'),
  }
]
