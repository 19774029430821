export default [
  {
    path: '/cases/case/list',
    name: 'apps-case-list',
    component: () => import('@/views/case/received/list/'),
    meta: {
      pageTitle: 'Lista de Casos',
      breadcrumb: [
        {
          text: 'Casos',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  },
  {
    path: '/cases/case/not_located/list',
    name: 'apps-case-not-located-list',
    component: () => import('@/views/case/received/list_no_located/'),
    meta: {
      pageTitle: 'Lista de Casos',
      breadcrumb: [
        {
          text: 'Casos',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  },
  {
    path: '/cases/case/following/list',
    name: 'apps-case-following-list',
    component: () => import('@/views/case/received/list_following/'),
    meta: {
      pageTitle: 'Lista de Casos',
      breadcrumb: [
        {
          text: 'Casos',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  },
  {
    path: '/cases/case/freezer/list',
    name: 'apps-case-freezer-list',
    component: () => import('@/views/case/received/list_freezer/'),
    meta: {
      pageTitle: 'Lista de Casos',
      breadcrumb: [
        {
          text: 'Casos',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  },
  {
    path: '/cases/case/view/:id',
    name: 'apps-case-view',
    component: () => import('@/views/case/received/view/'),
  },
  {
    path: '/cases/case/edit/:id/:origin',
    name: 'apps-case-edit',
    redirect: to => {
      return { path: '/cases/case/internal_edit/'+to.params.id+'/none/'+to.params.origin }
    },
  },
  {
    path: '/cases/received/medical_order/:case_id',
    name: 'apps-medical-order',
    component: () => import('@/views/case/received/medical_order/'),
  },
  {
    path: '/cases/case/follow_edit/:id',
    name: 'apps-case-follow-edit',
    redirect: to => {
      return { path: '/cases/case/internal_edit/'+to.params.id+'/FOLLOW/following' }
    },
  },
  {
    path: '/cases/case/internal_edit/:id/:role/:origin',
    name: 'apps-case-edit',
    component: () => import('@/views/case/received/edit/'),
  },
  {
    path: '/cases/case/create/',
    name: 'apps-case-create',
    component: () => import('@/views/case/received/edit/'),
  }
]
