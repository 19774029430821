export default [
  {
    path: '/apps/countries/list/',
    name: 'apps-country-list',
    redirect: to => {
      return { path: '/apps/entity/list/geography/country' }
    },
  },
  {
    path: '/apps/countries/view/:id',
    name: 'apps-country-view',
    redirect: to => {
      return { path: '/apps/entity/view/country/'+to.params.id+'/department' }
    },
  },
  {
    path: '/apps/countries/edit/:id',
    name: 'apps-country-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/geography/country/'+to.params.id+'/department' }
    },
  },
  {
    path: '/apps/countries/create',
    name: 'apps-country-create',
    redirect: to => {
      return { path: '/apps/entity/create/geography/country' }
    },
  }
]
