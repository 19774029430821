export default [
  // TODO --> Buscador de órdenes
  {
    path: '/arl_codes/list',
    name: 'arl-codes-list',
    component: () => import('@/views/arl_codes/list/'),
    meta: {
      pageTitle: 'Lista de Códigos de ARL',
      breadcrumb: [
        {
          text: 'Códigos de ARL',
        },
        {
          text: 'Lista',
          active: true,
        },
      ],
    },
  },
  {
    path: '/arl_codes/edit/:id',
    name: 'arl-codes-edit',
    component: () => import('@/views/arl_codes/edit/'),
  },
  {
    path: '/arl_codes/upload',
    name: 'arl-codes-upload',
    component: () => import('@/views/arl_codes/upload/'),
    meta: {
      pageTitle: 'Importación de códigos ARL',
      breadcrumb: [
        {
          text: 'Códigos ARL',
        },
        {
          text: 'Importar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/arl_codes/search',
    name: 'apps-arl-codes-search',
    component: () => import('@/views/arl_codes/search/index.vue'),
    meta: {
      pageTitle: 'Buscador de órdenes',
      breadcrumb: [
        {
          text: 'Buscador de órdenes',
          active: true,
        },
      ],
    },
  },
  // TODO --> Buscador de códigos ARL
  {
    path: '/arl_packages/list',
    name: 'arl-packages-list',
    component: () => import('@/views/arl_codes/list/'),
    meta: {
      pageTitle: 'Lista de Códigos de ARL',
      breadcrumb: [
        {
          text: 'Códigos de ARL',
        },
        {
          text: 'Lista',
          active: true,
        },
      ],
    },
  },
  {
    path: '/arl_packages/edit/:id',
    name: 'arl-packages-edit',
    component: () => import('@/views/arl_codes/edit/'),
  },
  {
    path: '/arl_packages/upload',
    name: 'arl-packages-upload',
    component: () => import('@/views/arl_codes/upload/'),
    meta: {
      pageTitle: 'Importación de códigos ARL',
      breadcrumb: [
        {
          text: 'Códigos ARL',
        },
        {
          text: 'Importar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/arl_packages/search',
    name: 'apps-arl-packages-search',
    component: () => import('@/views/arl_codes/search/index.vue'),
    meta: {
      pageTitle: 'Buscador de códigos ARL',
      breadcrumb: [
        {
          text: 'Buscador de códigos ARL',
          active: true,
        },
      ],
    },
  },
]
