export default [
  {
    path: '/apps/process_noti_type/list/',
    name: 'apps-process-noti-type-list',
    redirect: to => {
      return { path: '/apps/entity/list/process_noti_type/process_noti_type' }
    },
  },
  {
    path: '/apps/process_noti_type/view/:id',
    name: 'apps-process-noti-type-view',
    redirect: to => {
      return { path: '/apps/entity/view/process_noti_type/'+to.params.id }
    },
  },
  {
    path: '/apps/process_noti_type/edit/:id',
    name: 'apps-process-noti-type-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/process_noti_type/process_noti_type/'+to.params.id }
    },
  },
  {
    path: '/apps/process_noti_type/create/',
    name: 'apps-process-noti-type-create',
    redirect: to => {
      return { path: '/apps/entity/create/process_noti_type/process_noti_type' }
    },
  }
]
