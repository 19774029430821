export default [
  {
    path: '/apps/reference_unit/list/',
    name: 'apps-reference-unit-list',
    redirect: to => {
      return { path: '/apps/entity/list/reference_unit/reference_unit' }
    },
  },
  {
    path: '/apps/reference_unit/view/:id',
    name: 'apps-reference-unit-view',
    redirect: to => {
      return { path: '/apps/entity/view/reference_unit/'+to.params.id }
    },
  },
  {
    path: '/apps/reference_unit/edit/:id',
    name: 'apps-reference-unit-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/reference_unit/reference_unit/'+to.params.id }
    },
  },
  {
    path: '/apps/reference_unit/create/',
    name: 'apps-reference-unit-create',
    redirect: to => {
      return { path: '/apps/entity/create/reference_unit/reference_unit' }
    },
  }
]
