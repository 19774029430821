export default [
    {
      path: '/freezer/upload',
      name: 'freezer-upload',
      component: () => import('@/views/freezer/upload/'),
      meta: {
        pageTitle: 'Importación de Guías',
        breadcrumb: [
          {
            text: 'Guías',
          },
          {
            text: 'Importar',
            active: true,
          },
        ]
      }
    }
  ]