export default [
  {
    path: '/apps/entity/list/:search_type/:type/:deep?/:parent_id?/:son_type?',
    name: 'apps-entity-list',
    component: () => import('@/views/entity/list/'),
    props: true,
  },
  {
    path: '/apps/entity/view/:type/:id/:son_type?/:parent_id?',
    name: 'apps-entity-view',
    component: () => import('@/views/entity/view/'),
  },
  {
    path: '/apps/entity/edit/:search_type/:type/:id/:son_type?/:parent_id?/:parent_type?',
    name: 'apps-entity-edit',
    component: () => import('@/views/entity/edit/'),
  },
  {
    path: '/apps/entity/create/:search_type/:type/:son_type?/:parent_type?/:deep?/:parent_id?',
    name: 'apps-entity-create',
    component: () => import('@/views/entity/edit/'),
  }
]
