export default [
  {
    path: '/apps/department/list/:parent_id',
    name: 'apps-department-list',
    redirect: to => {
      return { path: '/apps/entity/list/geography/department/2/'+to.params.parent_id+'/city/' }
    },
  },
  {
    path: '/apps/department/view/:id/:parent_id',
    name: 'apps-department-view',
    redirect: to => {
      return { path: '/apps/entity/view/department/'+to.params.id+'/city/'+to.params.parent_id }
    },
  },
  {
    path: '/apps/department/edit/:id/:parent_id',
    name: 'apps-department-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/geography/department/'+to.params.id+'/city/'+to.params.parent_id+'/country' }
    },
  },
  {
    path: '/apps/department/create/:parent_id',
    name: 'apps-department-create',
    redirect: to => {
      return { path: '/apps/entity/create/geography/department/city/country/2/' + to.params.parent_id }
    },
  }
]
