export default [
  {
    path: '/apps/process_messages/list/',
    name: 'apps-process-messages-list',
    redirect: to => ({ path: '/apps/entity/list/process_messages/process_messages' }),
  },
  {
    path: '/apps/process_messages/view/:id',
    name: 'apps-process-messages-view',
    redirect: to => ({ path: `/apps/entity/view/process_messages/${to.params.id}` }),
  },
  {
    path: '/apps/process_messages/edit/:id',
    name: 'apps-process-messages-edit',
    redirect: to => ({ path: `/apps/entity/edit/process_messages/process_messages/${to.params.id}` }),
  },
  {
    path: '/apps/process_messages/create/',
    name: 'apps-process-messages-create',
    redirect: to => ({ path: '/apps/entity/create/process_messages/process_messages' }),
  },
]
