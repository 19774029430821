export default [
  {
    path: '/apps/sample_status/list/',
    name: 'apps-sample-status-list',
    redirect: to => {
      return { path: '/apps/entity/list/sample_status/sample_status' }
    },
  },
  {
    path: '/apps/sample_status/view/:id',
    name: 'apps-sample-status-view',
    redirect: to => {
      return { path: '/apps/entity/view/sample_status/'+to.params.id }
    },
  },
  {
    path: '/apps/sample_status/edit/:id',
    name: 'apps-sample-status-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/sample_status/sample_status/'+to.params.id }
    },
  },
  {
    path: '/apps/sample_status/create/',
    name: 'apps-sample-status-create',
    redirect: to => {
      return { path: '/apps/entity/create/sample_status/sample_status' }
    },
  }
]
