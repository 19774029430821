export default [
  {
    path: '/apps/logbook_result/list/',
    name: 'apps-logbook-result-list',
    redirect: to => {
      return { path: '/apps/entity/list/logbook_result/logbook_result' }
    },
  },
  {
    path: '/apps/logbook_result/view/:id',
    name: 'apps-logbook-result-view',
    redirect: to => {
      return { path: '/apps/entity/view/logbook_result/'+to.params.id }
    },
  },
  {
    path: '/apps/logbook_result/edit/:id',
    name: 'apps-logbook-result-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/logbook_result/logbook_result/'+to.params.id }
    },
  },
  {
    path: '/apps/logbook_result/create/',
    name: 'apps-logbook-result-create',
    redirect: to => {
      return { path: '/apps/entity/create/logbook_result/logbook_result' }
    },
  }
]
