export default [
    {
        path: '/logbook/list/:case_id',
        name: 'apps-logbook-list',
        component: () => import('@/views/logbook/list/'),
        meta: {
            pageTitle: 'Registros de Bitácora - Caso # ### ',
            breadcrumb: [
              {
                text: 'Bitácora',
              },
              {
                text: 'Lista',
                active: true,
              },
            ]
          },
        beforeEnter: (to, from, next) => {
            const case_id = to.params.case_id
            if (case_id) {
                to.meta.pageTitle = 'Registros de Bitácora'
            }
            next()
        }
    },
    {
      path: '/apps/logbook/create/:case_id',
      name: 'apps-logbook-create',
      component: () => import('@/views/logbook/edit/'),
    },
    {
      path: '/admin/logbook/view/:id',
      name: 'apps-logbook-view',
      component: () => import('@/views/logbook/view/'),
    }
]