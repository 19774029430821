export default [
    {
      path: '/admin/templates/list',
      name: 'apps-templates-list',
      component: () => import('@/views/template/list/'),
      meta: {
        pageTitle: 'Lista de plantillas',
        breadcrumb: [
          {
            text: 'Plantillas',
          },
          {
            text: 'Lista',
            active: true,
          },
        ]
      }
    },
    { 
      path: '/admin/templates/view/:id',
      name: 'apps-templates-view',
      component: () => import('@/views/template/view/'),
    },
    {
      path: '/admin/templates/edit/:id',
      name: 'apps-templates-edit',
      component: () => import('@/views/template/edit/'),
    },
    {
      path: '/admin/templates/create',
      name: 'apps-templates-create',
      component: () => import('@/views/template/edit/'),
    },
   
  ]
  