export default [
  {
    path: '/apps/city/list/:parent_id',
    name: 'apps-city-list',
    redirect: to => {
      return { path: '/apps/entity/list/geography/city/3/'+to.params.parent_id+'/town/' }
    },
  },
  {
    path: '/apps/city/view/:id/:parent_id',
    name: 'apps-city-view',
    redirect: to => {
      return { path: '/apps/entity/view/city/'+to.params.id+'/undefined/'+to.params.parent_id }
    },
  },
  {
    path: '/apps/city/edit/:id/:parent_id',
    name: 'apps-city-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/geography/city/'+to.params.id+'/undefined/'+to.params.parent_id+'/department' }
    },
  },
  {
    path: '/apps/city/create/:parent_id',
    name: 'apps-city-create',
    redirect: to => {
      return { path: '/apps/entity/create/geography/city/undefined/department/3/' + to.params.parent_id }
    },
  }
]
