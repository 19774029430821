export default [
  {
    path: '/apps/delivery_unit/list/',
    name: 'apps-delivery-unit-list',
    redirect: to => {
      return { path: '/apps/entity/list/delivery_unit/delivery_unit' }
    },
  },
  {
    path: '/apps/delivery_unit/view/:id',
    name: 'apps-delivery-unit-view',
    redirect: to => {
      return { path: '/apps/entity/view/delivery_unit/'+to.params.id }
    },
  },
  {
    path: '/apps/delivery_unit/edit/:id',
    name: 'apps-delivery-unit-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/delivery_unit/delivery_unit/'+to.params.id }
    },
  },
  {
    path: '/apps/delivery_unit/create/',
    name: 'apps-delivery-unit-create',
    redirect: to => {
      return { path: '/apps/entity/create/delivery_unit/delivery_unit' }
    },
  }
]
