export default [
  {
    path: '/admin/service_cost/list',
    name: 'apps-service-cost-list',
    component: () => import('@/views/service_cost/list/'),
    meta: {
      pageTitle: 'Tarifas de Servicios',
      breadcrumb: [
        {
          text: 'Servicios',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  },
  {
    path: '/admin/service_cost/view/:id',
    name: 'apps-service-cost-view',
    component: () => import('@/views/service_cost/view/'),
  },
  {
    path: '/admin/service_cost/edit/:id',
    name: 'apps-service-cost-edit',
    component: () => import('@/views/service_cost/edit/'),
  },
  {
    path: '/apps/service_cost/create/',
    name: 'apps-service-cost-create',
    component: () => import('@/views/service_cost/edit/'),
  }
]
