export default [
  {
    path: '/admin/documental_type/list',
    name: 'apps-documental-type-list',
    component: () => import('@/views/documental_type/list/'),
    meta: {
      pageTitle: 'Lista de Tipos Documentales',
      breadcrumb: [
        {
          text: 'Tipos documentales',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  },
  {
    path: '/admin/documental_type/view/:id',
    name: 'apps-documental-type-view',
    component: () => import('@/views/documental_type/view/'),
  },
  {
    path: '/admin/documental_type/edit/:id',
    name: 'apps-documental-type-edit',
    component: () => import('@/views/documental_type/edit/'),
  },
  {
    path: '/apps/documental_type/create/',
    name: 'apps-documental-type-create',
    component: () => import('@/views/documental_type/edit/'),
  }
]
