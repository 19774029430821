export default [
  {
    path: '/admin/stock_medicine/list',
    name: 'apps-stock-medicine-list',
    component: () => import('@/views/stock_medicine/list/'),
    meta: {
      pageTitle: 'Lista de Inventarios',
      breadcrumb: [
        {
          text: 'Inventarios',
        },
        {
          text: 'Lista',
          active: true,
        },
      ]
    }
  },
  {
    path: '/admin/stock_medicine/view/:id',
    name: 'apps-stock-medicine-view',
    component: () => import('@/views/stock_medicine/view/'),
  },
  {
    path: '/admin/stock_medicine/edit/:id',
    name: 'apps-stock-medicine-edit',
    component: () => import('@/views/stock_medicine/edit/'),
  },
]
