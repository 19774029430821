export default [
  {
    path: '/apps/reception_status/list/',
    name: 'apps-reception-status-list',
    redirect: to => {
      return { path: '/apps/entity/list/reception_status/reception_status' }
    },
  },
  {
    path: '/apps/reception_status/view/:id',
    name: 'apps-reception-status-view',
    redirect: to => {
      return { path: '/apps/entity/view/reception_status/'+to.params.id }
    },
  },
  {
    path: '/apps/reception_status/edit/:id',
    name: 'apps-reception-status-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/reception_status/reception_status/'+to.params.id }
    },
  },
  {
    path: '/apps/reception_status/create/',
    name: 'apps-reception-status-create',
    redirect: to => {
      return { path: '/apps/entity/create/reception_status/reception_status' }
    },
  }
]
