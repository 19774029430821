export default [
  {
    path: '/apps/booleanNA/list/',
    name: 'apps-booleanNA-list',
    redirect: to => {
      return { path: '/apps/entity/list/booleanNA/booleanNA' }
    },
  },
  {
    path: '/apps/booleanNA/view/:id',
    name: 'apps-booleanNA-view',
    redirect: to => {
      return { path: '/apps/entity/view/booleanNA/'+to.params.id }
    },
  },
  {
    path: '/apps/booleanNA/edit/:id',
    name: 'apps-booleanNA-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/booleanNA/booleanNA/'+to.params.id }
    },
  },
  {
    path: '/apps/booleanNA/create/',
    name: 'apps-booleanNA-create',
    redirect: to => {
      return { path: '/apps/entity/create/booleanNA/booleanNA' }
    },
  }
]
