export default [
  {
    path: '/apps/freezer_status/list/',
    name: 'apps-freezer-status-list',
    redirect: to => {
      return { path: '/apps/entity/list/freezer_status/freezer_status' }
    },
  },
  {
    path: '/apps/freezer_status/view/:id',
    name: 'apps-freezer-status-view',
    redirect: to => {
      return { path: '/apps/entity/view/freezer_status/'+to.params.id }
    },
  },
  {
    path: '/apps/freezer_status/edit/:id',
    name: 'apps-freezer-status-edit',
    redirect: to => {
      return { path: '/apps/entity/edit/freezer_status/freezer_status/'+to.params.id }
    },
  },
  {
    path: '/apps/freezer_status/create/',
    name: 'apps-freezer-status-create',
    redirect: to => {
      return { path: '/apps/entity/create/freezer_status/freezer_status' }
    },
  }
]
