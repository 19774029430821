export default [
  {
    path: '/cases/labtests/list/:case_id/:origin',
    name: 'apps-labtest-list',
    component: () => import('@/views/case/labtests/list'),
    meta: {
      pageTitle: 'Exámenes de Laboratorio',
      breadcrumb: [
        {
          text: 'Casos',
        },
        {
          text: 'Lista de exámenes',
          active: true,
        },
      ]
    }
  },
  {
    path: '/cases/labtests/view/:case_id/:id',
    name: 'apps-labtest-view',
    component: () => import('@/views/case/labtests/view/'),
  },
  {
    path: '/cases/labtests/edit/:case_id/:id',
    name: 'apps-labtest-edit',
    component: () => import('@/views/case/labtests/edit/'),
  },
  {
    path: '/cases/labtests/create/:case_id/:type',
    name: 'apps-labtest-create',
    component: () => import('@/views/case/labtests/edit/'),
  }
]
